import request from "../../../api/httpClient";
import { notify } from '@kyvg/vue3-notification';
import router from '@/router';

export default {
  state: {
    orderPendingConfirmRequest: window.localStorage.getItem('orderPendingConfirmRequest') || null,

    cart_link: null,
    confirmed_order_link: null,
    restaurant: [],
    idRestaurant: null,
    slugRestaurant: null,
    idTable: window.localStorage.getItem("idTable") ?? 0,
    tableList: [],
    canTakeOut: false,
    canEatIn: false,
    canDelivery: false,
    subscriptions: [],
    GuestOnlineOrder: 0,
    GuestTableOrder: 0,
    disabledAtPlace: false,
    disabledByTime: false,
    disabledEndTime: "",
    takeOutEnabled: false,
    deliveryEnabled: false,
    isOpenLiv: false,
    isOpenLivNow: false,
    isOpenCAC: false,
    isAvailable: false,
    isOpenCACNow: false,
    cacStatus: false,
    deliveryStatus: null,
    paymentAtPlace: null,
    isPaymentDirect: false,
    symbol: "EUR",
    workTime: null,
    enabledQRCodeAtPlace: true,
    enabledQRCodeDelivery: true,
    enabledQRTakeOut: true,
    minPrice: null,
    isOrderAnotherDayDisabled: false,
    isOrderNowDisabled: false,
    restaurantMessage: [],
    fee: 0,
    threshold: 0
  },
  mutations: {

    addPendingRequest(state: any, orderId: number) {
      state.orderPendingConfirmRequest = orderId
      window.localStorage.setItem('orderPendingConfirmRequest', state.orderPendingConfirmRequest);
    },
    removePendingRequest(state: any) {
      window.localStorage.removeItem('orderPendingConfirmRequest');
    },


    UPDATE_RESTAURANT_INFO(state: any, payload: any) {
      state.restaurant = payload;
      state.idRestaurant = payload.id;
      state.slugRestaurant = payload.slug;
      // state.linkCart = payload.urlCart;
    },
    UPDATE_WORKTIME_DATA(state: any, payload: any) {
      state.workTime = payload;
    },
    UPDATE_RESTAURANT_OPTIONS(state: any, payload: any) {
      state.subscriptions = payload.subscriptions;
      if (payload.products && payload.products.GuestOnlineOrder)
        state.GuestOnlineOrder = payload.products.GuestOnlineOrder;
      if (payload.products && payload.products.GuestTableOrder)
        state.GuestTableOrder = payload.products.GuestTableOrder;
      // state.canTakeOut=false;
      state.canEatIn = false;
    },

    UPDATE_RESTAURANT_ID(state: any, payload: any) {
      state.idRestaurant = payload;
      window.localStorage.setItem("idRestaurant", payload);
    },

    UPDATE_RESTAURANT_SLUG(state: any, payload: any) {
      state.idRestaurant = payload;
      window.localStorage.setItem("slugRestaurant", payload);
    },
    UPDATE_TABLE_ID(state: any, payload: any) {
      state.idTable = payload;
      window.localStorage.setItem("idTable", payload);
    },
    UPDATE_TABLE_LIST(state: any, payload: any) {
      state.tableList = payload;
    },
    UPDATE_DISABLED_AT_PLACE_LIST(state: any, payload: any) {
      state.disabledAtPlace = payload;
    },
    UPDATE_RESTAURANT_BLOCKED_TIME(state: any, payload: any) {
      state.disabledByTime = payload.disabled;
      state.disabledEndTime = payload.end;
    },
    UPDATE_RESTAURANT_CAC_AND_DELIVERY(state: any, payload: any) {
      state.isOpenLiv = payload.isOpenLiv;
      state.isOpenLivNow = payload.isOpenLivNow;
      state.isOpenCAC = payload.isOpenCAC;
      state.isAvailable = payload.isAvailable;
      state.isOpenCACNow = payload.isOpenCACNow;
      state.cacStatus = payload.cac_status;
      state.deliveryStatus = payload.delivery_status;
    },
    UPDATE_RESTAURANT_AT_PLACE(state: any, payload: any) {
      state.paymentAtPlace = payload.payment_at_place;
      state.isPaymentDirect = payload.isPaymentDirect;
    },
    UPDATE_SYMBOL(state: any, payload: any) {
      state.symbol = payload;
    },
    UPDATE_QR_CODE_OPTIONS(state: any, payload: any) {
      state.enabledQRCodeAtPlace = payload.enabledQRCodeAtPlace;
      state.enabledQRCodeDelivery = payload.enabledQRCodeDelivery;
      state.enabledQRTakeOut = payload.enabledQRTakeOut;
    },
    UPDATE_RESTAURANT_SETTINGS(state: any, payload: any) {
      state.minPrice = payload.min_price
      // state.isOrderAnotherDayDisabled = false;
      // state.isOrderNowDisabled = true;
      state.isOrderAnotherDayDisabled = !payload?.is_order_another_day
      state.isOrderNowDisabled = !payload?.is_order_now
    },
    UPDATE_RESTAURANT_FEE(state: any, payload: any) {
      state.fee = payload.delivery_fee.fees
      state.threshold = payload.delivery_fee.threshold
    },
    UPDATE_RESTAURANT_MESSAGES(state: any, payload: any) {
      state.restaurantMessage = payload.notificationMessages.filter((notification: any) =>
        notification.apps.includes("webapp")
      );
    },
    updateCartLink(state: any, cart_link: string) {
      state.cart_link = cart_link;
    },
    updateConfirmedOrderLink(state: any, confirmed_order_link: string) {
      state.confirmed_order_link = confirmed_order_link;


    }
  },
  actions: {

    checkPendingRequest({ state, commit }: any) {
      if (state.orderPendingConfirmRequest != null) {
        commit('removePendingRequest');
      } else {
        console.error('There is no peding request ');
      }
    },

    idToSlug({ commit }: any, idRestaurant: number) {
      commit("UPDATE_RESTAURANT_ID", idRestaurant);
      request
        .httpClient()
        .get("idToSlug/" + idRestaurant)
        .then((response) => {
          commit("UPDATE_RESTAURANT_SLUG", response.data.slug);
        });
    },

    getRestaurantInfo({ commit }: any, idRestaurant: number) {
      request
        .httpClient()
        .get("restaurant/" + idRestaurant)
        .then((response) => {
          commit("UPDATE_RESTAURANT_INFO", response.data.restaurant);
          commit("UPDATE_WORKTIME_DATA", response.data.workTime);
          commit("UPDATE_RESTAURANT_ID", response.data.restaurant.id);
        }).catch((e: any) => {

          notify({
            type: "error",
            text: "Le restaurant n'existe pas",
            duration: 2000,
          });
          router.push({ name: "errorview" });
        });
    },
    getRestaurantInfoById({ commit }: any, idRestaurant: number) {
      request
        .httpClient()
        .get("/restaurant/id/" + idRestaurant)
        .then((response) => {
          commit("UPDATE_RESTAURANT_INFO", response.data.restaurant);
          commit("UPDATE_WORKTIME_DATA", response.data.workTime);
          commit("UPDATE_RESTAURANT_ID", response.data.restaurant.id);
        }).catch((e: any) => {

          notify({
            type: "error",
            text: "Le restaurant n'existe pas",
            duration: 2000,
          });
          router.push({ name: "errorview" });
        });
    },
    getSymbolByIp({ commit }: any, idRestaurant: number) {
      request
        .httpClient()
        .get("/symbol_by_ip")
        .then((response) => {
          commit("UPDATE_SYMBOL", response.data[0]);
        });
    },
    getRestaurantOptions({ commit }: any, idRestaurant: number) {
      request
        .httpClient()
        .get("checkOptions/" + idRestaurant)
        .then((response) => {
          commit("UPDATE_RESTAURANT_OPTIONS", response.data);
        });
    },
    getRestaurantSettings({ commit }: any, idRestaurant: number) {
      request
        .httpClient()
        .get("restaurant_settings/" + idRestaurant)
        .then((response) => {
          commit("UPDATE_RESTAURANT_SETTINGS", response.data);
        });
    },
    getRestaurantDeliveryFee({ commit }: any, idRestaurant: number) {
      request
        .httpClient()
        .get("deliveryFee/" + idRestaurant)
        .then((response) => {
          commit("UPDATE_RESTAURANT_FEE", response.data);
        });
    },
    getRestaurantMessages({ commit }: any, idRestaurant: number) {
      request
        .httpClient()
        .get("messages/" + idRestaurant)
        .then((response) => {
          commit("UPDATE_RESTAURANT_MESSAGES", response.data);
        });
    },
    setRestaurantID({ commit }: any, restaurantID: number) {
      commit("UPDATE_RESTAURANT_ID", restaurantID);
    },

    setRestaurantSlug({ commit }: any, restaurantSlug: string) {

      commit("UPDATE_RESTAURANT_SLUG", restaurantSlug);
    },

    setTableID({ commit }: any, tableID: number) {
      commit("UPDATE_TABLE_ID", tableID);
    },

    setDisabledAtPlace({ commit }: any, disabled: boolean) {
      commit("UPDATE_DISABLED_AT_PLACE_LIST", disabled);
    },

    checkIfRestaurantIsBlocked({ commit }: any, restaurantSlug: string) {
      if (restaurantSlug)
        request
          .httpClient()
          .get("check_is_blocked/" + restaurantSlug)
          .then((response) => {
            commit("UPDATE_RESTAURANT_BLOCKED_TIME", response.data);
          });
    },
    getPlanRastauOpning({ commit }: any, restaurantSlug: string) {
      request
        .httpClient()
        .get("getPlanRastauOpning/" + restaurantSlug)
        .then((response: any) => {
          commit("UPDATE_RESTAURANT_CAC_AND_DELIVERY", response.data);
        });
    },
    getPlanRastauOpningAtPlace({ commit }: any, restaurantSlug: string) {
      // get opning for at place
      request
        .httpClient()
        .get("getPlanRastauOpningAtPlace/" + restaurantSlug)
        .then((response: any) => {
          commit("UPDATE_RESTAURANT_AT_PLACE", response.data);
        });
    },

    getTableList({ commit }: any, idRestaurant: number) {
      request
        .httpClient()
        .get("tables/" + idRestaurant)
        .then((response) => {
          commit(
            "UPDATE_TABLE_LIST",
            response.data.tables.sort((a: any, b: any) => {
              if (isNaN(a.tableNumber)) return 1;
              if (isNaN(b.tableNumber)) return -1;
              if (parseInt(a.tableNumber) > parseInt(b.tableNumber)) return 1;
              if (parseInt(a.tableNumber) < parseInt(b.tableNumber)) return -1;
              return 0;
            })
          );
        });
    },

    setQrCodeOptions({ commit }: any, payload: any) {
      commit("UPDATE_QR_CODE_OPTIONS", { enabledQRCodeAtPlace: payload.enabledQRCodeAtPlace, enabledQRCodeDelivery: payload.enabledQRCodeDelivery, enabledQRTakeOut: payload.enabledQRTakeOut });
    },
  },
  getters: {
    OrderPendingConfirmRequest: (state: any) => state.orderPendingConfirmRequest,
    cartLink: (state: any) => state.cart_link,
    confirmedOrderLink: (state: any) => state.confirmed_order_link,
    retaurantInfo: (state: any) => state.restaurant,
    idRestaurant: (state: any) => state.idRestaurant,
    slugRestaurant: (state: any) => {
      return state.slugRestaurant;
    },
    idTable: (state: any) => state.idTable,
    subscriptions: (state: any) => state.subscriptions,
    tableList: (state: any) => state.tableList,
    isDisabledAtPlace: (state: any) => state.disabledAtPlace,
    canEatIn: (state: any) => {
      if (
        state.GuestTableOrder > 0 &&
        state.subscriptions.some((e: any) => e.name === "GuestTableOrder")
      ) {
        return true;
      } else {
        return false;
      }
    },
    canTakeOut: (state: any) => {
      if (state.cacStatus === null) return null;
      if (
        state.GuestOnlineOrder > 0 &&
        state.subscriptions.some((e: any) => e.name === "GuestOnlineOrder") && state.cacStatus
      ) {
        return true;
      } else {
        return false;
      }
    },
    canDelivery: (state: any) => {
      if (state.deliveryStatus === null) return null;
      if (
        state.GuestOnlineOrder > 0 &&
        state.subscriptions.some((e: any) => e.name === "GuestOnlineOrder") && state.deliveryStatus
      ) {
        return true;
      } else {
        return false;
      }
    },
    disabledByTime: (state: any) => state.disabledByTime,
    disabledEndTime: (state: any) => state.disabledEndTime,
    symbol: (state: any) => state.symbol,
    workTime: (state: any) => {
      return state.workTime;
    },
    enabledQRCodeAtPlace: (state: any) => state.enabledQRCodeAtPlace,
    enabledQRCodeDelivery: (state: any) => state.enabledQRCodeDelivery,
    enabledQRTakeOut: (state: any) => state.enabledQRTakeOut,
    minPrice: (state: any) => state.minPrice,
    isOrderAnotherDayDisabled: (state: any) => state.isOrderAnotherDayDisabled,
    isOrderNowDisabled: (state: any) => state.isOrderNowDisabled,
    restaurantFee: (state: any) => state.fee,
    restaurantThreshold: (state: any) => state.threshold,
    restaurantMessage: (state: any) => state.restaurantMessage,
  },
};
