export default {
  "home": {
    
  },
  "order": {
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Basket"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order summary"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "deliveryfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery fee"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
    "eatin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAT IN"])},
    "takeaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take away"])},
    "ordertable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order at the table"])},
    "quanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "choise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choise(s)"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order now"])},
    "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without"])},
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredients"])},
    "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your choice"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
    "cb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
    "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select table"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])},
    "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
    "whene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When would you like to receive your order:"])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you want to pay:"])},
    "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "Deliveryfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery fee"])},
    "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not Available"])},
    "whichday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which day:"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "takeawayAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order for Takeaway"])},
    "deliveryAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Delivery"])},
    "eatinAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order In Place"])},
    "ordertableAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order at the table"])},
    "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add product"])},
    "paidInTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid in table"])}
  },
  "confirm": {
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Confirmed"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't process the payment"])},
    "tryagain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])}
  },
  "cac": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a collection point."])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
  },
  "scan": {
    "again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like to place a new order, please rescan the QR code."])}
  },
  "customer": {
    "stayintouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay in touch"])},
    "deliveryInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery address"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address2"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
    "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zip code"])}
  },
  "message": {
    "lastnameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LastName is required."])},
    "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required."])},
    "pleaseWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait for the order to be processed."])},
    "orderAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order was successfully placed."])},
    "orderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOPS Something wrong happens have we couldn't placed your order"])},
    "customerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUPS Something wrong happened we couldn't add your information."])},
    "itemAddedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The item has been successfully added to cart!"])},
    "missingSuppliments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose all requirements"])},
    "notavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product not available"])},
    "unvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, contact a manager."])},
    "ERROR_CAISSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred. Please check with your restaurant"])},
    "ERROR_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred. Please check with your restaurant"])},
    "FAILED_LOADING_XML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred. Please check with your restaurant"])},
    "TABLE_OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred. Please check with your restaurant"])},
    "TABLE_OCCUPIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred. Please check with your restaurant"])},
    "CONNECTION_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred. Please check with your restaurant"])},
    "reOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be able to place your order in one minute"])}
  },
  "error": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i m sorry, but the page you were looking for doesn't exist."])}
  },
  "download": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download our app"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By downloading our application, you can easily order  and remain notified. "])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
  },
  "suggestion": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It may interest you."])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No thanks"])}
  },
  "categories": {
    "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, products in this category are not currently available."])}
  },
  "restaurant": {
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Orders are disabled until"])}
  },
  "day": {
    "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "Saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "Sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])}
  }
}