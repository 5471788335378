<template>
  <Transition name="modal">
    <div v-if="show">
      <div class="mfp-bg my-mfp-zoom-in mfp-ready"></div>
      <div class="
          mfp-wrap  mfp-auto-cursor
          my-mfp-zoom-in
          mfp-ready
        " tabindex="-1" style="overflow: hidden auto">
        <div class="mfp-container mfp-inline-holder">
          <div class="mfp-container mfp-inline-holder">
            <div class="mfp-content">
              <div id="modal-dialog" class="downloadAPP">

                <div class="content" id="sign-in-dialog">
                  <div class="row">
                    <div class="popup_wrapper">
                      <div class="popup_content">
                        <div class="col-md-12">
                          <div class="content">
                            <div class="wrapper">
                              <div v-for="notification in restaurantMessage" :key="notification.id"
                                :class="['d-flex', 'align-items-center', 'p-3', 'my-3', 'text-white', notification.type === 'danger' ? 'bg-danger' : notification.type === 'warning' ? 'bg-warning' : notification.type === 'success' ? 'bg-success' : '', 'rounded', 'box-shadow']">
                                {{ notification.title }}: {{ notification.content }}
                              </div>
                              <button type="submit" style="margin-left: 68px;" class="btn_1 mt-2 mb-4" @click="close()">
                                OK
                              </button>
                            </div>
                          </div>
                        </div>

                        <!-- row -->
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
@Options({
  props: {
    show: Boolean,
    laoading: Boolean,
    restaurantMessage: Array
  },

  data() {
    return {
      errors: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    openLink() {
      window.open(this.link, '_blank');
    }
  },
  components: {},
  computed: {
    ...mapGetters(["orderType"]),
  },
})
export default class CommandeTypeModal extends Vue { }
</script>

<style>
.modal-dialog-nonbackground {
  background: none !important;
}

#modal-dialog {
  position: relative !important;

}

.downloadAPP {
  max-width: 300px !important;
}

form {
  padding: 0px 3px 14px 3px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

#sign-in-dialog {
  background: none !important;
  margin: 16px auto !important;
}

.newsletter_c {
  margin-top: 40px;
}

#modal-dialog .content {
  padding: 1px 20px 11px 20px !important;
}

.small-dialog-header {
  display: flex !important;
  justify-content: center;
}

:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 32px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.select-container {
  width: 140px;
  background: rgb(255, 255, 255);
  border-radius: 22px;
  padding: 20px;
}

.options {
  flex: 1;
  justify-content: center;
  display: flex;
}

.select-type {
  justify-content: center;
  display: flex;
}

.title-select {
  color: #fff;
  text-align: center;
}

.select-container-type {
  display: flex;
  padding: 0px;
}

.top-bar-hider {
  background: none !important;
  color: #fff !important;
}
</style>